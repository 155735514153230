export enum AllowedTabCategories {
    news = "news",
    videos = "videos",
    tickets = "tickets",
    lineup = "lineup",
    liveticker = "liveticker",
    radio = "radio",
    liveTable = "live-table",
    statistics = "statistics",
}
