// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Classes
import { PlayerFieldPosition } from "../enums/player-field-position.enum";

@Pipe({
    name: "fieldPosition"
})
export class FieldPositionPipe implements PipeTransform {

    transform(value: PlayerFieldPosition): string {
        switch (value) {
            case PlayerFieldPosition.goal:
                return "Tor";
            case PlayerFieldPosition.defense:
                return "Abwehr";
            case PlayerFieldPosition.midfield:
                return "Mittelfeld";
            case PlayerFieldPosition.offense:
                return "Angriff";
            default:
                return "";
        }
    }
}
