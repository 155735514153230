// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AllowedMessageCategories } from "../enums/allowed-message-categories";

@Pipe({
    name: "category"
})
export class CategoryPipe implements PipeTransform {

    transform(value: AllowedMessageCategories): any {
        switch (value) {
            case AllowedMessageCategories.general:
                return "category-pipe.general";
            case AllowedMessageCategories.news:
                return "category-pipe.news";
            case AllowedMessageCategories.tippingGame:
                return "category-pipe.tipping-game";
            case AllowedMessageCategories.matchday:
                return "category-pipe.matchday";
            case AllowedMessageCategories.standings:
                return "category-pipe.standings";
            case AllowedMessageCategories.poll:
                return "category-pipe.poll";
            case AllowedMessageCategories.squadProfis:
                return "category-pipe.squad-profis";
            case AllowedMessageCategories.map:
                return "category-pipe.map";
            case AllowedMessageCategories.adventCalendar:
                return "category-pipe.advent-calendar";
            // case AllowedMessageCategories.matchEvents:
            //     return "category-pipe.match-events";
            // case AllowedMessageCategories.voting:
            //     return "category-pipe.voting";
            // case AllowedMessageCategories.stadionServices:
            //     return "category-pipe.stadion-services";
            // case AllowedMessageCategories.promotions:
            //     return "category-pipe.promotions";
            default:
                return null;
        }
    }
}
