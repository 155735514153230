// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AllowedTabCategories } from "../enums/allowed-tab-categories";

@Pipe({
    name: "tab"
})
export class TabPipe implements PipeTransform {

    transform(value: AllowedTabCategories): any {
        switch (value) {
            case AllowedTabCategories.lineup:
                return "tab-pipe.lineup";
            case AllowedTabCategories.liveTable:
                return "tab-pipe.live-table";
            case AllowedTabCategories.liveticker:
                return "tab-pipe.liveticker";
            case AllowedTabCategories.news:
                return "tab-pipe.news";
            case AllowedTabCategories.radio:
                return "tab-pipe.radio";
            case AllowedTabCategories.statistics:
                return "tab-pipe.statistics";
            case AllowedTabCategories.tickets:
                return "tab-pipe.tickets";
            case AllowedTabCategories.videos:
                return "tab-pipe.videos";
            default:
                return null;
        }
    }
}
