export enum AllowedMessageCategories {
    general = "general",
    news = "news",
    tippingGame = "tipping-game",
    matchday = "matchday",
    standings = "standings",
    poll = "poll",
    squadProfis = "squad-profis",
    map = "map",
    adventCalendar = "advent-calendar",
    // smb
    // matchEvents = "match-events",
    // voting = "voting",
    // stadionServices = "stadion-services",
    // promotions = "promotions",
}
