// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Classes
import { ClubStatisticsAddition } from "../enums/club-statistics-addition.enum";

@Pipe({
    name: "clubStatisticsAddition"
})
export class ClubStatisticsAdditionPipe implements PipeTransform {

    transform(value: ClubStatisticsAddition): string {
        switch (value) {
            case ClubStatisticsAddition.savesMade:
                return "Paraden (insgesamt)";
            case ClubStatisticsAddition.savesMadeFromOutsideBox:
                return "Paraden (Fernschüsse)";
            case ClubStatisticsAddition.successfulDistributionPercentage:
                return "Erfolgreiche Spieleröffnungen";
            case ClubStatisticsAddition.recoveries:
                return "Balleroberungen";
            case ClubStatisticsAddition.successfulPassesPercentage:
                return "Passquote";
            case ClubStatisticsAddition.groundDuelsWonPercentage:
                return "Zweikampfquote (Boden)";
            case ClubStatisticsAddition.aerialDuelsWonPercentage:
                return "Zweikampfquote (Luft)";
            case ClubStatisticsAddition.duelsWonPercentage:
                return "Zweikampfquote";
            case ClubStatisticsAddition.smbGoalAssists:
                return "Torvorlagen";
            case ClubStatisticsAddition.forwardPasses:
                return "Offensivpässe";
            case ClubStatisticsAddition.keyPasses:
                return "Schlüsselpass";
            case ClubStatisticsAddition.totalTouchesInOppositionBox:
                return "Ballkontakte (gegn. Strafraum)";
            case ClubStatisticsAddition.goalShots:
                return "Torschüsse";
            default:
                return "";
        }
    }
}
