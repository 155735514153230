// Framework
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Plugins
import { ImageCropperModule } from "ngx-image-cropper";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Project modules
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { ShareRoutingModule } from "./share-routing.module";
// Components
import { ShareComponent } from "./components/share/share.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
// Directives
import { InputLengthDirective } from "./directives/input-length.directive";
// Pipes
import { GenderPipe } from "./pipes/gender.pipe";
import { LivetickerPipe } from "./pipes/liveticker.pipe";
import { CategoryPipe } from "./pipes/category.pipe";
import { FieldPositionPipe } from "./pipes/field-position.pipe";
import { ClubStatisticsAdditionPipe } from "./pipes/club-statistics-addition.pipe";
import { TabPipe } from "./pipes/tab.pipe";

@NgModule({
    declarations: [
        ShareComponent,
        NotFoundComponent,
        GenderPipe,
        FieldPositionPipe,
        ClubStatisticsAdditionPipe,
        LivetickerPipe,
        CategoryPipe,
        TabPipe,
        InputLengthDirective,
    ],
    imports: [
        CommonModule,
        ShareRoutingModule,
        TranslateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            maxOpened: 5,
            toastClass: "",
            iconClasses: {
                success: "alert alert--success",
                error: "alert alert--danger",
                info: "alert alert--info",
                warning: "alert alert--warning"
            },
            // individual
            closeButton: false,
            tapToDismiss: true,
            progressBar: true,
        }),
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        NgbModule,
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        DatePipe,
        DecimalPipe,
    ],
    exports: [
        HttpClientModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        GenderPipe,
        FieldPositionPipe,
        ClubStatisticsAdditionPipe,
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        NgbModule,
        LivetickerPipe,
        CategoryPipe,
        TabPipe,
        ImageCropperModule,
        NgxSpinnerModule,
        NgbModule,
        InputLengthDirective,
    ]
})
export class ShareModule { }
