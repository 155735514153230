export enum ClubStatisticsAddition {
    savesMade = "savesMade",
    savesMadeFromOutsideBox = "savesMadeFromOutsideBox",
    successfulDistributionPercentage = "successfulDistributionPercentage",
    recoveries = "recoveries",
    successfulPassesPercentage = "successfulPassesPercentage",
    groundDuelsWonPercentage = "groundDuelsWonPercentage",
    aerialDuelsWonPercentage = "aerialDuelsWonPercentage",
    duelsWonPercentage = "duelsWonPercentage",
    smbGoalAssists = "smbGoalAssists",
    forwardPasses = "forwardPasses",
    keyPasses = "keyPasses",
    totalTouchesInOppositionBox = "totalTouchesInOppositionBox",
    goalShots = "goalShots"
}
