// Framework
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
// Project modules
import { AppRoutingModule } from "./app-routing.module";
import { ShareModule } from "@src/app/modules/share/share.module";
import { CookieModule } from "@src/app/modules/cookie/cookie.module";
// Plugins
import { NgcCookieConsentConfig, NgcCookieConsentModule } from "ngx-cookieconsent";
// Components
import { AppComponent } from "./app.component";
// Global
import { cookieAcceptedName, cookieDomain } from "@src/app/global";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: cookieDomain,
        name: cookieAcceptedName,
        expiryDays: 7,
    },
    position: "top-right",
    theme: "classic",
    palette: {
        popup: {
            background: "#F2F2F2",
            text: "#000000"
        },
        button: {
            background: "#4B6172",
            text: "#FFF"
        }
    },
    type: "info",
    content: {
        href: "privacy-policy",
    },
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ShareModule,
        CookieModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [{ provide: LOCALE_ID, useValue: "de-DE" }],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private translate: TranslateService) {
        registerLocaleData(localeDe, "de-DE");

        this.translate.setDefaultLang("de_formal");
        this.translate.use("de_formal");
    }
}
